<!--
*  TTTech nerve-ui-components
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-row no-gutters>
    <span>
      {{ tableItem.serviceName }}
    </span>
    <v-tooltip bottom v-if="tableItem.file.status === 'failed' && tableItem.errorMessage">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-img
          :id="`iiotComposeServiceNameErrorIcon_${index}`"
          src="/img/warning.svg"
          max-height="17px"
          max-width="17px"
          class="ml-4 mt-1"
        />
        </div>
      </template>
      <span>
        {{ $t('errorMessages.WORKLOAD_DOCKER_IMG_DOWNLOAD_ERROR') +
          ': "' + tableItem.errorMessage + '"' }}
      </span>
    </v-tooltip>
  </v-row>
  </template>

<script>
export default {
  props: {
    tableItem: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style scoped>
  .margin-top-2 {
    margin-top: 2px;
  }
</style>
